import { graphql } from "gatsby"
import Container from "../components/container"
import Header from "../components/header"
import Intro from "../components/intro"
import Navigation from "../components/navigation"

import { HelmetDatoCms } from "gatsby-source-datocms"
import MoreStories from "../components/moreStories"
import Section from "../components/section"
import SectionSeparator from "../components/sectionSeparator"
import { useEffect, useRef, useState } from "react"
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useForm, ValidationError } from "@formspree/react"

export default function Configurator({ data }) {
  const { allPosts, allSections, allSteps, site, blog, form } = data
  const sections = allSections.nodes
  const steps = allSteps.nodes
  const morePosts = allPosts.nodes
  const currentSection = sections.find((section) => section.name === "Konfigurator")
  const stepContainerRef = useRef(null)
  const sortedSections = [...sections].sort(
    (a, b) => new Date(a.meta.publishedAt).getTime() - new Date(b.meta.publishedAt).getTime()
  )

  type Option = {
    label: string
    price: number
    details: string
    image?: any
    goToContactForm: boolean
  }

  const basePrice = 100000
  const [selectedOptions, setSelectedOptions] = useState<{ [key: number]: Option | null }>({ 1: steps[0].options[0] })
  const [currentStep, setCurrentStep] = useState(0)

  const handleOptionChange = (step: number, option: Option) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [step]: option,
    }))
  }

  const handleSelectOptionChange = (step: number, option: Option) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [step]: option,
    }))
  }

  const [formState, handleSubmit] = useForm("mkgwpdra")

  const [isOpen, setIsOpen] = useState(false)
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const toggleModal = () => setIsOpen(!isOpen)

  const calculateTotalPrice = () =>
    Object.values(selectedOptions).reduce((total, option) => {
      if (option) {
        return total + option.price
      }
      return total
    }, basePrice)

  const handleNextStep = () => {
    if (selectedOptions[currentStep + 1].goToContactForm) {
      setIsOpen(true)
    } else {
      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1)
        scrollToTop()
      }
    }
  }

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
      scrollToTop()
    }
  }

  const scrollToTop = () => {
    if (stepContainerRef.current) {
      stepContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      })
    }
  }

  const formatPriceClass = (price: number) => {
    if (price > 0) return "text-black"
    if (price < 0) return "text-black"
    return "text-black"
  }

  const formatPriceLabel = (price: number) => {
    if (price === 0) return "w cenie"
    return price > 0 ? `+${price} zł` : `${price} zł`
  }

  const [currentSelected, setIsSelected] = useState()

  const handleTileClick = (label) => {
    setIsSelected(label) // Przełączamy stan zaznaczenia
  }

  const handleDisplaySummary = () => {
    const message = `Wybrałeś ${Object.keys(selectedOptions)
      .map((key) => selectedOptions[key].label.toLowerCase())
      .join(", ")} na łączną kwotę ${calculateTotalPrice().toFixed(2) + "zł"}`

    alert(message)
  }

  const triggerNotification = () => {
    toast.success("Wiadomość została wysłana")
  }

  useEffect(() => {
    if (!formState.succeeded) {
      return
    }
    setIsOpen(false)
    setEmail("")
    setMessage("")
    triggerNotification()
  }, [formState.succeeded])

  return (
    <div className="bg-gray-100 min-h-screen">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <HelmetDatoCms seo={blog?.seo} favicon={site.favicon} />
      <Navigation sections={sortedSections} />

      <main className="container mx-auto px-4">
        {/* Modal z formularzem */}
        {isOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            {/* Overlay wyszarzający tło */}
            <div className="fixed inset-0 bg-black bg-opacity-50" onClick={toggleModal}></div>

            {/* Zawartość modala */}
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg mx-2 md:mx-auto z-10">
              <h2 className="text-xl font-bold mb-4 text-left">{form.header}</h2>
              <h4 className="text-md font-semibold mb-4 text-left">{form.description}</h4>

              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Adres e-mail
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus-visible:border-indigo-500"
                  />
                  <ValidationError prefix="Email" field="email" errors={formState.errors} />
                </div>

                <div className="mb-4">
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                    Wiadomość
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    rows={4}
                  ></textarea>
                  <ValidationError prefix="Message" field="message" errors={formState.errors} />
                </div>

                <div className="flex justify-end space-x-4">
                  {/* Przycisk Zamknij */}
                  <button
                    type="button"
                    onClick={toggleModal}
                    className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                  >
                    Zamknij
                  </button>
                  {/* Przycisk Wyślij */}
                  <button
                    type="submit"
                    className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700"
                    disabled={formState.submitting}
                  >
                    Wyślij
                  </button>
                </div>
                <ValidationError errors={formState.errors} />
              </form>
            </div>
          </div>
        )}

        <Section
          bgColor={currentSection.background}
          title={`${currentSection.name} - ${currentSection.header}`}
          id={currentSection.name}
          ref={stepContainerRef}
        >
          <div className="bg-white rounded-lg shadow-md p-6 mt-8 md:mt-12">
            <div className="flex items-baseline mb-8">
              <h2 className="text-xl font-extrabold text-gray-700">
                Krok {steps[currentStep].step} - {steps[currentStep].title}
              </h2>
            </div>

            <div>
              {currentStep === 0 ? (
                <div className="relative">
                  <select
                    id="options"
                    onChange={(el) =>
                      handleOptionChange(
                        steps[currentStep].step,
                        steps[currentStep].options.find((e) => e.label === el.target.value)
                      )
                    }
                    className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 border-b2 focus:border-indigo-500 sm:text-sm  "
                  >
                    {steps[currentStep].options.map((option) => {
                      return <option value={option.label}>{option.label}</option>
                    })}
                  </select>

                  <div className="w-full">
                    <div className="my-4 mt-4">
                      <span className="block text-sm text-gray-900 tracking-wider">{selectedOptions[1].details}</span>
                    </div>
                    {selectedOptions[1].image && (
                      <GatsbyImage
                        image={selectedOptions[1].image.gatsbyImageData}
                        alt={`Cover Image for ${selectedOptions[1].label}`}
                        className="h-auto max-w-full rounded-lg mx-auto"
                      />
                    )}
                    <div
                      className={`absolute -top-4 right-2 bg-white px-3 py-1 rounded-full shadow-md text-sm font-bold ${formatPriceClass(
                        selectedOptions[1].price
                      )} text-right`}
                    >
                      {formatPriceLabel(selectedOptions[1].price)}
                    </div>
                  </div>
                </div>
              ) : (
                steps[currentStep].options.map((option) => {
                  const isSelected = currentSelected === option.label

                  return (
                    <label
                      key={option.label}
                      className={`block mb-8 ${
                        isSelected ? "bg-sky-300" : "bg-sky-50 hover:bg-sky-200"
                      }  hover:-translate-y-1 p-3 px-8 pt-4 pb-8 rounded-md transition-transform duration-300 cursor-pointer relative`}
                      onClick={() => handleTileClick(option.label)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="w-full">
                          <input
                            type="radio"
                            name={`step-${steps[currentStep].step}`}
                            value={option.label}
                            onChange={() => handleOptionChange(steps[currentStep].step, option)}
                            checked={selectedOptions[steps[currentStep].step]?.label === option.label}
                            className="mr-2"
                          />
                          <span className="text-lg font-bold">{option.label}</span>
                          <div className="my-4">
                            <span className="block text-sm text-gray-900 tracking-wider">{option.details}</span>
                          </div>
                          {option.image && (
                            <GatsbyImage
                              image={option.image.gatsbyImageData}
                              alt={`Cover Image for ${option.label}`}
                              className="h-auto max-w-full rounded-lg mx-auto"
                            />
                          )}
                        </div>
                      </div>
                      <div
                        className={`absolute -top-4 right-2 bg-white px-3 py-1 rounded-full shadow-md text-sm font-bold ${formatPriceClass(
                          option.price
                        )} text-right`}
                      >
                        {formatPriceLabel(option.price)}
                      </div>
                    </label>
                  )
                })
              )}
            </div>

            <div className={`flex ${currentStep > 0 ? "justify-end" : "justify-end"} mt-12 gap-2`}>
              {currentStep !== 0 && (
                <button
                  onClick={handlePrevStep}
                  className={`bg-gray-300 px-4 py-2 rounded ${currentStep === 0 && "opacity-50 cursor-not-allowed"}`}
                >
                  Wstecz
                </button>
              )}
              {currentStep < steps.length - 1 ? (
                <button
                  onClick={handleNextStep}
                  className={`bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 ${
                    !selectedOptions[currentStep + 1] && "opacity-50 cursor-not-allowed"
                  }`}
                  disabled={!selectedOptions[currentStep + 1]}
                >
                  Dalej
                </button>
              ) : (
                Object.keys(selectedOptions).length === 5 && (
                  <button
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
                    onClick={handleDisplaySummary}
                  >
                    Wyślij zapytanie
                  </button>
                )
              )}
            </div>

            <SectionSeparator />
            <div className="text-2xl font-bold text-right">
              Łączna cena: <span className={formatPriceClass(calculateTotalPrice())}>{calculateTotalPrice()} zł</span>
            </div>
          </div>
        </Section>

        <SectionSeparator />
        {morePosts.length > 0 && (
          <Container>
            <MoreStories posts={morePosts} />
          </Container>
        )}
      </main>
    </div>
  )
}

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    blog: datoCmsBlog {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allPosts: allDatoCmsPost(sort: { fields: date, order: DESC }, limit: 20) {
      nodes {
        title
        slug
        excerpt
        date
        coverImage {
          large: gatsbyImageData(width: 1500)
          small: gatsbyImageData(width: 750)
        }
        author {
          name
          picture {
            gatsbyImageData(layout: FIXED, width: 48, height: 48, imgixParams: { sat: -100 })
          }
        }
      }
    }
    allSections: allDatoCmsSection(sort: { fields: index, order: ASC }, limit: 20) {
      nodes {
        name
        background {
          hex
        }
        meta {
          publishedAt
        }
        description
        external
        gallery {
          gatsbyImageData(imgixParams: {})
        }
        header
        href
        id
      }
    }
    allSteps: allDatoCmsStepConfigurator(sort: { order: ASC, fields: step }) {
      nodes {
        step
        title
        options {
          price
          details
          goToContactForm
          label
          image {
            gatsbyImageData(imgixParams: {})
          }
        }
      }
    }
    form: datoCmsForm {
      description
      header
    }
  }
`
